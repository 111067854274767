import React from "react";
import Modal from "../components/Modal";
import SEO from "../components/Seo";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing";
import DefaultPage from "../components/DefaultPage";
import Section from "../components/Section";
import SectionHeader from "../components/SectionHeader";
import Obfuscate from "react-obfuscate";

import "../sass/main.scss";

const ImprintPage = () => {
  const content = (
    <>
      <SEO title="Rebecca Schulz - Impressum" />
      <Section>
        <SectionHeader headline="Impressum" />
        <h2>Herausgeber</h2>
        <p>
          Inhaber: Rebecca Lehners
          <br />
          c/o AutorenServices.de
          <br />
          Birkenallee 24
          <br />
          36037 Fulda <br />
          E-Mail:
          <Obfuscate
            email="rebecca.lehners.autorin@gmx.de"
            headers={{
              subject: "Kontakt über www.rebecca-lehners.de",
              body: "Liebe Frau Lehners, ...",
            }}
            obfuscate={true}
          />
        </p>
      </Section>
    </>
  );

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div>
          {modal ? (
            <Modal closeTo={closeTo}>{content}</Modal>
          ) : (
            <DefaultPage nav={false}>{content}</DefaultPage>
          )}
        </div>
      )}
    </ModalRoutingContext.Consumer>
  );
};

export default ImprintPage;
